import styled from "styled-components";
import { FaBarsStaggered } from "react-icons/fa6";
import { CgClose } from "react-icons/cg";
import { FaHome, FaMoneyCheck, FaNetworkWired } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";
import { TbHelpCircle } from "react-icons/tb";
import { GoCodespaces } from "react-icons/go";

export const Flag = styled.button`
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
`;
export const CountryContainer = styled.div`
  position: relative;
`;
export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  height: 5em;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  &.shadow {
    box-shadow: 0 9px 11px -7px #eeecec;
  }
  z-index: 999;
  border-bottom: 1px solid #f2f2f2;
`;

export const Navegacion = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);*/
  width: 100%;
  /*gap: 8em;*/

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    padding: 0 15px;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    padding: 0 50px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    padding: 0 50px;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    padding: 0 10%; /* Cambia el padding personalizado si es necesario */
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    padding: 0 20%; /* Cambia el padding personalizado si es necesario */
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    padding: 0 20%;
    height: 7rem;
  }
`;

export const Panel = styled.nav`
  background-image: linear-gradient(96deg, #5e60e7 0%, #9c7af2 65%, #9c7af2 71.35%);
  color: #ffff;
  position: fixed;
  top: 0;
  backdrop-filter: blur(2px);
  bottom: 0;
  left: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  right: 0;
  transition: transform 0.3s ease;

  transform: translate(-100%, 0);
  &.active {
    transform: translate(0, 0);
  }

  @media (max-width: 1020px) {
    width: 65%;
    top: 80px;
  }

  @media (min-width: 1020px) {
    box-shadow: none;
    border: none;
    transform: translate(0, 0);
    background: none;
    position: relative;
  }
`;

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2em;

  @media (min-width: 1020px) {
    flex-direction: row;
    width: 20em;
    align-items: normal;
    width: inherit;
    margin-top: 0em;
  }
`;

export const NavLink = styled.span`
  padding: 1em;
  width: inherit;
  font-size: 0.8em;
  text-decoration: none;
  transition: 0.2s;
  font-weight: 600;
  font-family: Raleway;
  color: #4a4949;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  &.active {
    background: rgba(113, 65, 217, 0.85);
    color: #fff;
    font-weight: 700;
  }
  @media (max-width: 1020px) {
    color: #fff;
    margin-bottom: 0.2em;
  }

  @media (min-width: 1020px) {
    padding: 1em;
    text-transform: uppercase;
    &.active {
      background: transparent;
      color: #a772f2;
    }
    &:hover {
      color: #827c7c;
    }
  }
`;
export const NavLinkMobile = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8em;
  font-family: Raleway;
  
  @media (min-width: 850px) {
    padding: 0.8em;
    text-transform: uppercase;
    color: #ababab;
    font-weight: 600;
    &:hover {
      color: #827c7c;
    }
`;
export const SignUp = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: 1020px) {
    display: flex;
  }
`;
export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const HomeIcon = styled(FaHome)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const MoneyIcon = styled(FaMoneyCheck)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const NetworkIcon = styled(FaNetworkWired)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const TeamIcon = styled(RiTeamFill)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const YoutubeIcon = styled(AiOutlineYoutube)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const HelpIcon = styled(TbHelpCircle)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const CodespaceIcon = styled(GoCodespaces)`
  @media (min-width: 850px) {
    display: none;
  }
`;
export const BurgerBtn = styled(FaBarsStaggered)`
  display: flex;
  z-index: 999;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  font-size: 30px;
  color: ${(props: any) => props?.theme?.colors?.primary};
  @media screen and (min-width: 1020px) {
    display: none;
  }
`;
export const CloseBtn = styled(CgClose)`
  display: flex;
  z-index: 999;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  font-size: 30px;
  margin-top: 4px;
  color: ${(props: any) => props?.theme?.colors?.primary};
  @media screen and (min-width: 1020px) {
    display: none;
  }
`;
export const FlagsContainer = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  padding: 8px;
  background: rgb(239, 239, 239);
  backdrop-filter: blur(75px);
  border-radius: 2px;
  border: transparent;
  width: 200px;
  overflow: hidden;

  & > div:first-child {
    display: inline-flex;
    position: relative;
    align-items: center;
    border-radius: 2px;
    width: 100%;
    height: 40px;
    background: #f4f5fb;
    margin: 0 0 8px;
  }

  & input {
    width: 100%;
    height: 100%;
    padding: 0 40px 0 40px;
    border: none;
    outline: none;
    background: transparent;
  }

  @media (max-width: 960px) {
    right: 0;
    left: auto;
  }
`;
export const FlagButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: #f4f5fb;
    transition: 0.3s;
  }
`;
