// src/styles/theme.ts
export interface ThemeI {
  colors: {
    primary: string;
    secondary: string;
    background: string;
    text: string;
    gradient: string;
  };
  fonts: {
    body: string;
    heading: string;
  };
}

const theme: ThemeI = {
  colors: {
    primary: "#2D1B6E",
    secondary: "#7C8087",
    background: "linear-gradient(181deg, #FFF -49.5%, #F5F6FC 99.5%)",
    text: "#333",
    gradient: "linear-gradient(96deg, #5E60E7 0%, #9C7AF2 65%, #9C7AF2 71.35%)",
  },
  fonts: {
    body: "Raleway, sans-serif",
    heading: "Roboto, sans-serif",
  },
};

export default theme;
