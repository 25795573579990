// src/styles/GlobalStyles.ts
import styled, { createGlobalStyle } from "styled-components";
import {
  BadgeI,
  ButtonI,
  ColumI,
  ContainerI,
  RowProps,
  SubContainersI,
  TextI,
} from "../types/style-interfaces";
import { NavLinkMobile } from "./components/Header";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.body};
  };
  body {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  
    
  .flagsContainer {
    display: flex;
    align-items: center;
    margin-top: 2em;
    gap: 1em;
    justify-content: center;
  }

  .flags {
    width: 5em;   
    height: 3em;
    margin-right: 0.5em;
  }
  .flagSelectorMobile {
    display: none;
  }

  @media (max-width: 600px) {
    .flags {
    width: 44px;  
    height: 26px;
    margin-right: 1em;
    }

    .flagsContainer {
      flex-direction: column;
    }
    
    .flagSelectorMobile {
      display: flex;
    }

  .carousel .control-dots .dot {
    width: 15px;
    height: 15px;
  }


  @media (max-width: 850px){
    .hero-image {
      margin-bottom: 40px
    }
  }
  @media (max-width: 1080px) {
    .flagsContainer{
      margin-top: 1em;
    }
   
  
`;

export const BgCityHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff 0, #eceefa 100%);
  padding: 2rem 0;
`;

export const Container = styled.div<ContainerI>`
  width: ${(props) => props.$width || "100vw"};
  height: ${(props) => props.$height || "100%"};
  padding: ${(props) => props.$padding || "20px 180px"};
  background: ${(props) => props.theme.colors?.background};
  margin: 0 auto;

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    padding: ${(props) => props.$paddingSm || "20px"};
    margin-top: ${(props) => props.$marginXsm || "auto"};
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    padding: ${(props) => props.$paddingMd || "40px"};
    margin-top: ${(props) => props.$marginSm || "auto"};
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    padding: ${(props) => props.$paddingMd || "40px"};
    margin-top: ${(props) => props.$marginMd || "auto"};
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    padding: ${(props) =>
      props.$paddingLg || "40px 10%"}; /* Cambia el padding personalizado si es necesario */
    margin-top: ${(props) => props.$marginMd || "auto"};
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    padding: ${(props) => props.$paddingMd || "40px 20%"};
    margin-top: ${(props) => props.$marginLg || "auto"};
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    width: ${(props) => props.$width || "100vw"};
    height: ${(props) => props.$height || "100vh"};
    padding: ${(props) => props.$padding || "0 20%"};
    margin-top: ${(props) => props.$marginXlg || "auto"};
  }
`;

export const PartnersConatiner = styled.div`
  border: solid 1px #9c7af2;
  padding: 5px;
  border-radius: 10px;
  width: max-content;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Title = styled.h1<TextI>`
  color: ${(props) => props?.$textColor || props?.theme?.colors?.primary};
  font-size: ${(props) => props.$fontSize || "3rem"};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "left"};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: ${(props) => props.$xsfontSize || "2em"};
    text-align: center;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 2em;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 2em;
    text-align: center;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 3.2rem;
    line-height: 3.22rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 3.2rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 9em;
    line-height: 9.33rem;
  }
`;

export const SubTitle = styled.h2<TextI>`
  color: ${(props) => props?.$textColor || props?.theme?.colors?.primary};
  font-size: ${(props) => props.$fontSize || "3rem"};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "left"};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: ${(props) => props.$xsfontSize || "2em"};
    text-align: center;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 2em;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 2em;
    text-align: center;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 3.2rem;
    line-height: 3.22rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 3.2rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 9em;
    line-height: 9.33rem;
  }
`;

export const TextBenfit = styled.p<TextI>`
  color: ${(props) => props?.$textColor || props?.theme?.colors?.primary};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "center"};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1.2em;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1.2em;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 2.2em; //antes en 1.2em
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 2.2em; //antes en 1.2em
    line-height: 2.33rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 3.2rem;
    line-height: 3.22rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 6em;
  }
`;

export const MediumText = styled.p<TextI>`
  color: ${(props) => props?.$textColor || props.theme.colors?.secondary};
  font-family: Raleway;
  font-size: ${(props) => props.$fontSize || "1em"};
  font-style: normal;
  font-weight: ${(props) => props.$textWeight || 600};
  text-align: ${(props) => props.$textAlign || "center"};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  line-height: ${(props) => props.$lineHeight || "40px"};
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    text-align: ${(props) => props.$textAligmXsm || "center"};
    font-size: 1rem;
    line-height: 1.3;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 1.2rem;
    line-height: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 1.2rem;
    line-height: 2rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1.4rem;
    line-height: 2rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 3rem;
    line-height: 4rem;
  }
`;

export const AccordionText = styled.p<TextI>`
  color: white;
  font-family: Raleway;
  font-size: ${(props) => props.$fontSize || "1em"};
  font-style: normal;
  font-weight: 600;
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  line-height: ${(props) => props.$lineHeight || "40px"};
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 1920px) {
    font-size: 2em;
  }

  @media (max-width: 850px) {
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 1em;
  }

  /* Media query para 4K */
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    font-size: ${(props) => props.$fontSize || "2em"};
  }
`;
export const AccordionTitle = styled.p<TextI>`
  color: white;
  font-family: Raleway;
  font-size: ${(props) => props.$fontSize || "1em"};
  font-style: normal;
  font-weight: 1000;
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  line-height: ${(props) => props.$lineHeight || "40px"};
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 1920px) {
    font-size: 2em;
  }

  @media (max-width: 850px) {
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 1em;
  }

  /* Media query para 4K */
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    font-size: ${(props) => props.$fontSize || "2em"};
  }
`;

export const Badge = styled.div<BadgeI>`
  color: ${(props) => props.$textColor || "#FFFF"};
  padding: 4px 12px;
  border-radius: 26px;
  background: ${(props) => props.$bgColor || "#21CC75"};
  background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  max-width: ${(props) => props.$maxWidth || "fit-content"};
  text-align: center;
  margin-top: ${(props) => props.$marginTop || "auto"};

  /* Media query para 4K */
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    font-size: 3rem;
  }
`;

export const Column = styled.div<ColumI>`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection || "column"};
  justify-content: ${(props) => props.$justifyContent || "center"};
  width: ${(props) => props.$width || "50%"};
  gap: ${(props) => `${props.$gap}` || "0px"};
  align-items: ${(props) => props.$alignItems || "normal"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    margin-top: ${(props) => props.$marginXsm || "0px"};
  }
  @media (max-width: 960px) {
    display: flex;
    flex-direction: ${(props) => props.$forceDirection || "column"};
    justify-content: center;
    align-items: ${(props) => props.$alignItems || "center"};
    width: 100%;
    margin-top: ${(props) => props.$marginXsm || "0px"};
  }
  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    margin-top: ${(props) => props.$marginSm};
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    margin-top: ${(props) => props.$marginMd};
    align-items: ${(props) => props.$alignItems || "center"};
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    margin-top: ${(props) => props.$marginMd};
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    margin-top: ${(props) => props.$marginLg}; /* Cambia el padding personalizado si es necesario */
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    margin-top: ${(props) => props.$marginXlg};
  }
`;

export const RowCounter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;

  @media (max-width: 600px) {
    width: 90%; /* Extra small devices (phones, 600px and down) */
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    width: 90%;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
  }
`;

export const SubContainers = styled.div<SubContainersI>`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection || "row"};
  background: ${(props) => props.$backgroundColor};
  justify-content: ${(props) => props.$justifyContent || "center"};
  align-items: center;
  min-height: ${(props) => props.$minHeight || "100%"};
  gap: 2rem;

  @media (max-width: 960px) {
    flex-direction: ${(props) => props.$flexDirectionR || "column"};
    justify-content: ${(props) => props.$justifyContentR || "center"};
    align-items: ${(props) => props.$alignItemsR || "center"};
    gap: ${(props) => `${props.$gapSm}` || "2rem"};
    height: inherit;
  }
  @media (min-width: 768px) and (max-width: 1365px) {
    gap: 2rem;
  }
  @media (min-width: 1280px) {
    gap: ${(props) => `${props.$gapLg}` || "0px"};
  }
`;

export const Button = styled.button<ButtonI>`
  background-image: ${(props) =>
    (props.$buttonType === "primary" &&
      "linear-gradient(96deg, #5E60E7 0%, #9C7AF2 65%, #9C7AF2 71.35%)") ||
    (props.$buttonType === "danger" && "#fa4c2a")};

  border: none;
  padding: 15px;
  border-radius: 5px;
  border: ${(props) =>
    // (props.$buttonType === "primary" && "2px solid #5E60E7") ||
    (props.$buttonType === "danger" && "2px solid #0000") ||
    (props.$buttonType === "transparent" && `2px solid #5945E6`)};
  width: ${(props) => props.$buttonSize || "18em"};
  color: ${(props) =>
    (props.$buttonType === "primary" && "#FFFF") ||
    (props.$buttonType === "danger" && "#FFFF") ||
    (props.$buttonType === "transparent" && "#5945E6")};
  font-weight: 600;
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;

  &:hover {
    background: ${(props) =>
      (props.$buttonType === "danger" && "#f96447") ||
      (props.$buttonType === "transparent" && "#5E60E7")};
    border: ${(props) =>
      // (props.$buttonType === "primary" && "2px solid #38CB89") ||
      (props.$buttonType === "danger" && "2px solid #0000") ||
      (props.$buttonType === "transparent" && `2px solid #5945E6`)};
    color: ${(props) =>
      (props.$buttonType === "danger" && "#FFFF") ||
      (props.$buttonType === "transparent" && "#FFF")};
    background-position: right center;
  }
`;

export const BigTextPrincipal = styled.p<TextI>`
  color: #2d1b6e;
  font-size: ${(props) => props.$fontSize || "3.3rem"};
  font-family: Raleway;
  // font-style: normal;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "center"};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};

  @media (min-width: 1920px) {
    font-size: 4.5em;
  }

  @media (max-width: 850px) {
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 2.2em;
  }
  /* line-height: 60px; */
`;

export const BigTextCity = styled.h1<TextI>`
  color: #2d1b6e;
  font-family: Raleway;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "center"};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    text-align: ${(props) => props.$textAlignR || "center"};
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    text-align: ${(props) => props.$textAlignR || "center"};
    font-size: 2rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 2rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 3rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 3rem;
  }
  /* line-height: 60px; */
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection || "row"};
  justify-content: ${(props) => props.$justifyContent || "flex-start"};
  align-items: ${(props) => props.$alignItems || "center"};
  width: ${(props) => props.$width || "100%"};
  margin-top: ${(props) => props.$marginTop || "0px"};

  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
  }
`;
export const StyledImage = styled.img`
  /* Estilos personalizados para la imagen */
  height: 400px;
  width: 600px;
  border-radius: 1em;
  @media (max-width: 850px) {
    height: 200px;
    width: 300px;
    width: 100%;
  }
  /* Otros estilos personalizados */
`;

export const SubMenuContainer = styled.div`
  position: absolute;
  top: 146%;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  z-index: 1000;
  width: inherit;
  padding: 10px;
  display: inline-block;
  justify-content: start;
  align-items: start;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 850px) {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(75px);
    border-radius: 12px;
    border: transparent;
    width: 90%;
    top: 4px;
    left: 0px;
    transform: none;
    position: relative;
    margin-bottom: 4px;
   
`;

export const SubMenuOption = styled(NavLinkMobile)`
  display: flex;
  justify-content: left;
  margin-bottom: 2px;
  padding: 0.8em;

  @media (max-width: 850px) {
    z-index: 1000;
  }
`;

export const TitleCity = styled.h2<TextI>`
  color: #2d1b6e;
  font-family: Raleway;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "start"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1.2rem;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1.2rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 1.2rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1.2rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 2rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 2rem;
  }
  /* line-height: 60px; */
`;

export const SubTitleCity = styled.h3<TextI>`
  color: #2d1b6e;
  font-family: Raleway;
  font-weight: 700;
  text-align: ${(props) => props.$textAlign || "start"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1rem;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 1rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 1.5rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 1.5rem;
  }
  /* line-height: 60px; */
`;

export const TextCity = styled.p<TextI>`
  color: #000000;
  font-family: Raleway;
  font-weight: 300;
  text-align: ${(props) => props.$textAlign || "start"};

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 1rem;
    line-height: 1.3rem;
  }
  /* line-height: 60px; */
`;

export const ListCity = styled.span`
  color: #2d1b6e;
  font-family: Raleway;
  font-weight: 500;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 1rem;
    line-height: 1.3rem;
  }
  /* line-height: 60px; */
`;

export const BigTextPolicy = styled.h5<TextI>`
  color: #000;
  font-family: Raleway;
  font-weight: 700;
  text-align: center;

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 1rem;
    line-height: 1.3rem;
  }
  /* line-height: 60px; */
`;

export const TextPolicy = styled.ol<TextI>`
  color: #7e7e7e;
  font-family: Raleway;
  font-weight: 500;
  text-align: justify;
  padding-left: 0;

  @media (max-width: 600px) {
    /* Extra small devices (phones, 600px and down) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* Small devices (portrait tablets and large phones, 600px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    /* Medium devices (landscape tablets, 768px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1366px) and (max-width: 1919px) {
    /* Small Laptop */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    /* Large devices (laptops/desktops, 992px and up) */
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (min-width: 3840px) {
    /* Media query para 4K */
    font-size: 1rem;
    line-height: 1.3rem;
  }
  /* line-height: 60px; */
`;

export const TitlePolicy = styled.h2<TextI>`
  color: #2d1b6e;
  font-family: Raleway;
  font-weight: 700;
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  // @media (max-width: 600px) {
  //   /* Extra small devices (phones, 600px and down) */
  //   font-size: 2rem;
  //   line-height: 1.3rem;
  // }

  // @media (min-width: 600px) and (max-width: 767px) {
  //   /* Small devices (portrait tablets and large phones, 600px and up) */
  //   font-size: 2rem;
  //   line-height: 1.3rem;
  // }

  // @media (min-width: 768px) and (max-width: 1365px) {
  //   /* Medium devices (landscape tablets, 768px and up) */
  //   font-size: 2rem;
  //   line-height: 1.3rem;
  // }

  // @media (min-width: 1366px) and (max-width: 1919px) {
  //   /* Small Laptop */
  //   font-size: 2rem;
  //   line-height: 1.3rem;
  // }

  // @media (min-width: 1920px) and (max-width: 3839px) {
  //   /* Large devices (laptops/desktops, 992px and up) */
  //   font-size: 2rem;
  //   line-height: 1.3rem;
  // }

  // @media (min-width: 3840px) {
  //   /* Media query para 4K */
  //   font-size: 2rem;
  //   line-height: 1.3rem;
  // }
  // /* line-height: 60px; */
`;

export default GlobalStyles;
