import React, { createContext, useState } from "react";

declare global {
  type ChildrenProps = {
    children?: React.ReactNode;
  };
}

export const AppContext = createContext<any>({
  country: "",
});

export const AppContextProvider = (props: ChildrenProps) => {
  const [country, setCountry] = useState<string>(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("country") || "mx";
    }
    return "mx";
  });

  const updateCountry = (countryProp: string) => {
    setCountry(countryProp);
    localStorage.setItem("country", countryProp);
  };

  return (
    <AppContext.Provider
      value={{
        country,
        updateCountry,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
