import React from "react";
import loadjs from "loadjs";
import styled from "styled-components";

const Icon = styled.div`
  border-radius: 6px 6px 6px 2px;
  height: 17px;
  width: 38%;
  position: absolute;
  top: 37%;
  width: 38%;
  left: 32%;
  background: #fff;

  &::before {
    border-radius: 2px 2px 2px 2px;
    border-top: 5px;
    background-color: #2e1d6e !important;
    content: "";
    height: 2px;
    left: 4px;
    position: absolute;
    top: 5px;
    width: 65%;
  }

  &::after {
    border-radius: 2px 2px 2px 2px;
    content: "";
    height: 2px;
    left: 4px;
    position: absolute;
    top: 10px;
    width: 44%;
    background: #2e1d6e;
  }
`;

const Chat = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const handleLoad = () => {
    // Load Freshchat after the DOM has completely loaded
    loadjs("https://wchat.freshchat.com/js/widget.js", {
      async: true,
      success: () => {
        // @ts-ignore
        window.fcWidget.init({
          token: "8529e562-00fe-422a-a804-de9cea5daa47",
          host: "https://wchat.freshchat.com",
          config: {
            content: {
              headers: {
                chat: "Envíanos un mensaje",
              },
            },
          },
        });

        // @ts-ignore
        window.fcWidget.open();
        setIsLoaded(true);
      },
    });
  };

  return (
    <div>
      {!isLoaded ? (
        <button
          aria-label="Chat with us!"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "15px",
            borderRadius: "34px 8px 34px 34px",
            backgroundColor: "#2e1d6e",
            boxShadow: "0px 5px 4px 0 rgba(0, 0, 0, 0.26)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "60px",
            width: "60px",
            padding: "10px",
            color: "rgba(255, 255, 255)",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => handleLoad()}
        >
          <Icon></Icon>
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Chat;
