import React from "react";
import type { GatsbyBrowser } from "gatsby";
import { AppContextProvider } from "./src/context/AppContext.tsx";
import Chat from "./src/components/Chat.tsx";
import { ThemeProvider } from "styled-components";
import theme from "./src/styles/theme.ts";
import "aos/dist/aos.css";
import GlobalStyles from "./src/styles/GlobalStyles.ts";
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      {element}
      <Chat />
    </>
  );
};
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <GlobalStyles />
        {element}
      </AppContextProvider>
    </ThemeProvider>
  );
};
